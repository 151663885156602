import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ColumnChooser,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import { Editor, Toolbar } from 'ngx-editor';
declare var bootstrap: any;
import * as Notiflix from 'notiflix';
import { TooltipComponent, Position } from '@syncfusion/ej2-angular-popups';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile } from 'src/app/core/shared/models/profile';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { Company } from 'src/app/core/shared/models/company.model';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { HomeService } from '../home/services/home.service';
import { OpenWorkorderService } from '../open-workorder/services/open-workorder.service';
import { DetailWorkorderService } from './services/detail-workorder.service';
import { Template } from 'src/app/core/shared/models/Template';
import { TemplateMakerService } from '../template-maker/services/template-maker.service';
import { CommunService } from 'src/app/services/commun.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Subject } from 'rxjs';
import { Features } from 'src/app/models/accessGrantObject.model';
import { AccessService } from 'src/app/guard/access.service';
import { HeaderService } from 'src/app/components/header/services/header.service';
import { AppConfigService } from 'src/app/config/app.config.service';
import { HttpClient } from '@angular/common/http';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';
import { TaskControlService } from 'src/app/core/shared/services/task-control.service';

@Component({
  selector: 'app-detail-workorder',
  templateUrl: './detail-workorder.component.html',
  styleUrls: ['./detail-workorder.component.scss'],
})
export class DetailWorkorderComponent implements OnInit, OnDestroy {
  @ViewChild('descriptionElement2', { static: false })
  descriptionElement2?: any;
  @ViewChild('createdByProfilElement2', { static: false })
  createdByProfilElement2?: any;
  @ViewChild('descriptionElement1', { static: false })
  descriptionElement1?: any;
  @ViewChild('barcode') public barcode: any
  userProfil = '';
  pageName = 'detailWorkorder';
  editor: Editor;
  noteContent = '';
  isDefaultTesksSelected = false;
  isTaskSelected = false;
  bgTaskBtn = 'bg-primary ';
  defaultTesksBtnLabel = ' Insérer tâches par défaut';
  activeProfilBg = 'btn-light';
  actionDetailModalTitle = '';
  //Buttons background
  bgTaskBtn_Fr_Fr_A_D = 'btn-light';
  bgTaskBtn_Fr_Fr_A_G = 'btn-light';
  bgTaskBtn_Fr_Fr_C_D = 'btn-light';
  bgTaskBtn_Fr_Fr_C_G = 'btn-light';
  bgTaskBtn_Fr_Gr_G_D = 'btn-light';
  bgTaskBtn_Fr_Gr_G_G = 'btn-light';
  bgTaskBtn_Vi_Vi_A = 'btn-light';
  bgTaskBtn_Vi_Ca_C = 'btn-light';
  bgTaskBtn_Vi_Bo_C = 'btn-light';
  bgTaskBtn_Se_Wa_C = 'btn-light';
  bgTaskBtn_Gu_Ca_C = 'btn-light';
  bgTaskBtn_Gu_Co_C = 'btn-light';
  bgTaskBtn_Gs_Pl_A_G = 'btn-light';
  bgTaskBtn_Gs_Pl_C_G = 'btn-light';
  bgTaskBtn_Gs_Pl_A_D = 'btn-light';
  bgTaskBtn_Gs_Pl_C_D = 'btn-light';

  // Icons colors
  colorTaskIcon_Fr_Fr_A_D = '';
  colorTaskIcon_Fr_Fr_A_G = '';
  colorTaskIcon_Fr_Fr_C_D = '';
  colorTaskIcon_Fr_Fr_C_G = '';
  colorTaskIcon_Fr_Gr_G_D = '';
  colorTaskIcon_Fr_Gr_G_G = '';
  colorTaskIcon_Vi_Vi_A = '';
  colorTaskIcon_Vi_Ca_C = '';
  colorTaskIcon_Vi_Bo_C = '';
  colorTaskIcon_Se_Wa_C = '';
  colorTaskIcon_Gu_Ca_C = '';
  colorTaskIcon_Gu_Co_C = '';
  colorTaskIcon_Gs_Pl_A_G = '';
  colorTaskIcon_Gs_Pl_C_G = '';
  colorTaskIcon_Gs_Pl_A_D = '';
  colorTaskIcon_Gs_Pl_C_D = '';

  // Icons default colors
  colorDefaultTaskIcon_Fr_Fr_A_D = 'text-info';
  colorDefaultTaskIcon_Fr_Fr_C_D = 'text-info';
  colorDefaultTaskIcon_Fr_Fr_A_G = 'text-info';
  colorDefaultTaskIcon_Fr_Fr_C_G = 'text-info';
  colorDefaultTaskIcon_Fr_Gr_G_D = 'text-info';
  colorDefaultTaskIcon_Fr_Gr_G_G = 'text-info';
  colorDefaultTaskIcon_Vi_Vi_A = 'text-info';
  colorDefaultTaskIcon_Vi_Ca_C = 'text-info';
  colorDefaultTaskIcon_Vi_Bo_C = 'text-info';
  colorDefaultTaskIcon_Se_Wa_C = 'text-info';
  colorDefaultTaskIcon_Gu_Ca_C = 'text-info';
  colorDefaultTaskIcon_Gu_Co_C = 'text-info';
  colorDefaultTaskIcon_Gs_Pl_A_G = 'text-info';
  colorDefaultTaskIcon_Gs_Pl_C_G = 'text-info';
  colorDefaultTaskIcon_Gs_Pl_A_D = 'text-info';
  colorDefaultTaskIcon_Gs_Pl_C_D = 'text-info';

  // is task selected
  isTaskSelected_Fr_Fr_A_D = 'd-none';
  isTaskSelected_Fr_Fr_A_G = 'd-none';
  isTaskSelected_Fr_Fr_C_D = 'd-none';
  isTaskSelected_Fr_Fr_C_G = 'd-none';
  isTaskSelected_Fr_Gr_G_D = 'd-none';
  isTaskSelected_Fr_Gr_G_G = 'd-none';
  isTaskSelected_Fr_Ca_C_D = 'd-none';
  isTaskSelected_Fr_Ca_C_G = 'd-none';
  isTaskSelected_Fr_Ga_A_D = 'd-none';
  isTaskSelected_Fr_Ga_A_G = 'd-none';
  isTaskSelected_Fr_Ma_A_D = 'd-none';
  isTaskSelected_Fr_Ma_A_G = 'd-none';

  isTemplateReadOnly = false;
  public noteData: Object[];
  public currentWoData: object[];
  public workOrdersData: Object[];
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  closeOpenWorkorderpanelEvent: Subject<void> = new Subject<void>();
  onSelectedAllFinishedClickEvent: Subject<any> = new Subject<any>();
  onReopenGeneralClickEvent: Subject<any> = new Subject<any>();
  onSelectedAllValidateClickEvent: Subject<any> = new Subject<any>();
  openDetailTacheModalEvent: Subject<any> = new Subject<any>();
  populateExpensesArrayEvent: Subject<any> = new Subject<any>();
  populateItemsArrayEvent: Subject<any> = new Subject<any>();
  currentUserName = '';
  currentUserCompany: Company | undefined;
  showNoteRicthText = false;
  disableNoteRicthText = true;
  viewModeNote = 'V';
  currentNote = '';
  @ViewChild('notesGrid') notesGrid: GridComponent;
  @ViewChild('defaultRTE') defaultRTE: RichTextEditorComponent;
  equipement: Equipement = null;
  actionDetailModal = null;
  isBarCodeVisible = 'block';
  tryloop = 0
  public control: TooltipComponent;
  userProfilTitle = '';
  BIXI_userAuthObject: any = null;
  currentProfile: Profile = null;
  currentProfiles: Profile[] = [];
  categoryCode: string = '';
  workorderTemplate: Template = null;
  workOrderSystemId: string = '';
  globalSameTasks = null;
  workOrderNo = '';
  workOrdersNoArray = [];
  equipementNo: string = '';
  defDisplayNames: any[] = [];
  refreshAssetLinkEvent: Subject<void> = new Subject<void>();
  createdByTemplateId = '';
  currentWorkorder: any = null;
  itemWorkOrderLines: any[] = [];
  expenseWorkOrderLines: any[] = [];
  // currentTaskLineObjectClicked: any = null;
  activeModal = false;
  // workOrdersInstructions = [];
  locationsList = [];
  unitsOfMeasureList = [];
  itemsList = [];
  itemVariants = [];
  // editItemVariants = [];
  // btRoueScanModal = null;
  expensesList = [];
  associatedEquipTemplateModal = null;

  // btRoueNoToScanValue = "";
  allItemVariants = [];

  templateSideIndex = 0;

  BTandLines: {
    BT: any;
    expenses: any[];
    items: any[];
  } = null;

  equipementAssocieModal = null;

  sameTasks = null;
  taskMultipleValue = 1;

  taskLineDefinitions: any[] = [];
  taskCategoryByDocs: any[] = [];
  popup_BtTaskCategoryByDocs: any[] = [];
  // uomElement2DefaultValue = '';
  // hiddenExpensesDeleteLine = true;
  // hiddenItemsDeleteLine = true;
  // defaultMultiple = 0;
  EObjectToUpdate = null;
  // dataType = '';
  BTRoue = null;
  roue = null;
  roueWorkorder = null;
  templateRoue = null;

  bikePartsData: any;
  isPrintDetailBtVisible = '';
  taskValidation = 'V';
  taskRealisation = 'R';
  progressBarLineTask: any;
  isBTRoue = false;
  drawForPdf_index = 1

  editWorkOrderFeatures: Features = null;
  consumptionTrackData: object[];

  pdPageHeight = 0
  // pdfNbHeight = 1

  isUserBlocked: boolean = true;  
  disableProgressBarFinish: boolean = true;
  disableProgressBarApprouve: boolean = true;
  disabledTaskBtn: boolean = false;
  disableProgressBarFinishForMultipleTasksPurpose = false
  disableProgressBarApprouveForMultipleTasksPurpose: boolean = false

  isMultiCorrectifWorkOrder: boolean = false;
  leadWorkOrder: string = "";
    
  schemaVersion = AppConfigService.settings.bcSchemaversion;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private detailWorkorderService: DetailWorkorderService,
    private openWorkOrderService: OpenWorkorderService,
    private templateMakerService: TemplateMakerService,
    private communService: CommunService,
    private accessService: AccessService,
    private headerService: HeaderService,
    private colum: ElementRef,
    private detail: ElementRef,
    private http: HttpClient,
    private taskControlService: TaskControlService
  ) {}

  @HostListener('document:keypress', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    let defaultMaxContent = 250;
    var regex = /(<([^>]+)>)/gi;
    if (this.showNoteRicthText) {
      var result = this.noteContent.replace(regex, '');
      if (result.length >= defaultMaxContent) {
        Notiflix.Notify.warning(
          'Vous avez dépassez ' + defaultMaxContent + ' caractères',
          { timeout: 1500, width: "400px" }
        );
        event.preventDefault();
      } else {
        return null;
      }
    }
  }

  @HostListener('paste', ['$event']) onPaste(e: any) {
    let defaultMaxContent = 250;
    var regex = /(<([^>]+)>)/gi;
    if (this.showNoteRicthText) {
      var result = this.noteContent.replace(regex, '');
      if (result.length >= defaultMaxContent) {
        this.noteContent = '';
        Notiflix.Notify.warning(
          'Vous avez dépassez ' + defaultMaxContent + ' caractères',
          { timeout: 1500, width: "400px" }
        );
      } else {
        return null;
      }
    }
  }

  public managePermissions(){
    this.editWorkOrderFeatures = this.accessService.getFeatureByProfile(this.currentProfile.profil);  
    const workOrderLedSysId = this.storageService.getItem("Bixi_WorkOrderLedSysId");
    const currentWoStatus = this.storageService.getItem("Bixi_WorkOrderBixiStatus");
    this.taskControlService.blockActionsByStatusBtProfile(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany?.id,
          currentWoStatus,
          this.currentProfile?.profil,
          workOrderLedSysId   
      ).subscribe({
        next: (permissions) => {
          this.setupDetailBtPermissions(workOrderLedSysId, currentWoStatus, permissions.isBlockedAction, permissions.isRetrunScan);
        },
        error: (error) => {
          this.setupDetailBtPermissions(workOrderLedSysId, currentWoStatus, true, false);
        },
      }); 
    }     

  private setupDetailBtPermissions(woSysId:string, status: string, isBlockedAction: boolean, isRetrunScan: boolean){
    this.storageService.setBlockAndReturnScanObject(this.currentProfile?.profil, status, woSysId, isBlockedAction, isRetrunScan);
    // this.editWorkOrderFeatures = this.accessService.getFeatureByProfile(this.currentProfile.profil);   

    if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
      const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
      const scriptBcPermissions: Features = this.accessService.getFeatureByProfile(this.currentProfile.profil);
      this.isUserBlocked = isBlockedAction;
      this.disableProgressBarFinish = (statusApiPermissions?.isBlockedAction || !scriptBcPermissions?.terminate) ? true: false;
      this.disableProgressBarApprouve = (statusApiPermissions?.isBlockedAction || !scriptBcPermissions?.validate) ? true: false;    
    }  
  }
  
  logout() {
    Notiflix.Confirm.show(
      'Confirmation',
      'Êtes-vous sûr de vouloir vous déconnecter?',
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/');
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  createNewNote() {
    this.noteContent = '';
    this.showNoteRicthText = true;
    this.viewModeNote = 'A';
    this.disableNoteRicthText = false;

    this.defaultRTE.toolbarModule.refreshToolbarOverflow(); // To refresh RTE’s toolbar
    this.defaultRTE.setContentHeight();
  }

  showNote(numNote: string) {
    let selectNote = this.noteData.filter(
      (entry) => entry['num'] === numNote
    )[0];
    this.noteContent = selectNote['contenu']?.toString() || '';
    this.showNoteRicthText = true;
    this.viewModeNote = 'V';
    this.disableNoteRicthText = true;
  }

  editNote(numNote: string) {
    let selectNote = this.noteData.filter(
      (entry) => entry['num'] === numNote
    )[0];
    this.noteContent = selectNote['contenu']?.toString() || '';
    this.showNoteRicthText = true;
    this.viewModeNote = 'E';
    this.disableNoteRicthText = false;
  }

  deleteNote(numNote: string) {
    Notiflix.Confirm.show(
      'Confirmation',
      'Voulez-vous vraiment supprimer cette note?',
      'Confirmer',
      'Annuler',
      () => {
        this.confirmDleteNote(numNote);
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  confirmDleteNote(numNote: string) {
    let selectNote = this.noteData.filter(
      (entry) => entry['num'] === numNote
    )[0];
    const index = this.noteData.indexOf(selectNote);
    if (index > -1) {
      this.noteData.splice(index, 1);
    }
    this.notesGrid.refresh();
  }

  deleteSelectedRows(grid, data: Object[], checkType: boolean): void {
    let selectedRows = grid.getSelectedRecords();
    for (let k = 0; k < selectedRows.length; k++) {
      const index = data.indexOf(selectedRows[k]);
      if (index > -1) {
        if (!checkType || selectedRows[k]['Type'] !== 'Correctif')
          data.splice(index, 1);
      }
    }
  }

  saveNote() {
    if (this.viewModeNote == 'E') {
      this.showNoteRicthText = false;
      this.viewModeNote = 'V';
      this.disableNoteRicthText = true;
    } else {
      let currentDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm', 'en-US');
      let dataSize = this.noteData.length + 1;
      this.noteData.push({
        num: '220232-27-Note' + dataSize,
        contenu: this.noteContent,
        userUpdate: 'John Doe ' + dataSize,
        dateUpdate: currentDate,
      });
      this.notesGrid.refresh();
      this.showNoteRicthText = false;
      this.viewModeNote = 'V';
      this.disableNoteRicthText = true;
    }
  }

  cancelNote() {
    this.noteContent = '';
    this.showNoteRicthText = false;
    this.viewModeNote = 'V';
    this.disableNoteRicthText = true;
  }

  public created() {
    if (document.getElementById('right-pane')) {
      document
        .getElementById('right-pane')
        .addEventListener('click', this.onFocus.bind(this));
      document
        .getElementById('right-pane')
        .addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  public onFocus(args: any) {
    if (
      args &&
      !args.target.parentNode.parentNode.classList.contains('e-tooltip')
    ) {
      if (
        this.control &&
        document.getElementsByClassName('e-tooltip-wrap').length > 0
      ) {
        this.control.close();
      }
    }
  }

  public onScroll() {
    if (document.getElementsByClassName('e-tooltip-wrap').length > 0) {
      this.control.close();
    }
  }

  onpenEquipementAssocieModal() {
    this.equipementAssocieModal = new bootstrap.Modal(
      document.getElementById('associatedEquipModal'),
      {}
    );
    this.equipementAssocieModal.show();
  }

  createNewWorkOrder(equipRoue) {
    const assetNum = equipRoue.num;
    const data = { sourceBT: this.equipement.workOrderNo, assetNo: assetNum };
    this.storageService.setItem('equipementRoueSerialNumber', assetNum);
    const concatenatedUrl = 'createLinkedWos';
    this.openWorkOrderService
      .createElement(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        data,
        concatenatedUrl
      )
      .subscribe({
        next: (data) => {
          this.openWorkOrderService
            .getWorkorders(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              "no eq '" + data.workOrderNo + "'"
            )
            .subscribe({
              next: (workOrder) => {
                if (workOrder.value.length > 0) {
                  this.roueWorkorder = workOrder.value[0];
                  this.createdByTemplateId = workOrder.value[0].createdByTemplateId;
                  const concatenatedUrl ='/workOrders(' + this.roueWorkorder.systemId + ')';
                  this.openWorkOrderService
                    .editElement(
                      this.BIXI_userAuthObject.tenantId,
                      this.currentUserCompany.id,
                      this.roueWorkorder,
                      concatenatedUrl
                    )
                    .subscribe({
                      next: (wo) => {
                        this.roueWorkorder = wo;
                        this.detailWorkorderService
                          .getWorkOrderTemplateWithoutFilter(
                            this.BIXI_userAuthObject.tenantId,
                            this.currentUserCompany.id,
                            this.createdByTemplateId
                          )
                          .subscribe({
                            next: (workorderTemplate) => {
                              this.templateRoue = workorderTemplate;
                              this.detailWorkorderService
                                .getTaskDetails(
                                  this.BIXI_userAuthObject.tenantId,
                                  this.currentUserCompany.id
                                )
                                .subscribe({
                                  next: (definitions) => {
                                    this.taskLineDefinitions = definitions.value;
                                    this.popup_Bt_getTaskCategoryByDocs();
                                  },
                                  error: (error) => {},
                                });
                              Notiflix.Loading.remove();
                              Notiflix.Notify.success(
                                'Bon de tavail crée avec succès!',
                                { timeout: 3000, width: "400px" }
                              );
                              this.refreshAssetLinkEvent.next();
                              if (!this.associatedEquipTemplateModal) {
                                this.associatedEquipTemplateModal =
                                  new bootstrap.Modal(
                                    document.getElementById(
                                      'associatedEquipTemplateModal'
                                    ),
                                    {}
                                  );
                                this.associatedEquipTemplateModal.show();
                              } else {
                                this.associatedEquipTemplateModal.show();
                              }
                            },
                            error: (error) => {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.failure(
                                'Aucun template trouvé pour cet équipement',
                                {
                                  timeout: 3000,
                                  showOnlyTheLastOne: true, 
                                  width: "400px",
                                }
                              );
                            },
                          });
                      },
                      error: (error) => {},
                    });
                }
              },
              error: (error) => {},
            });
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });

    this.equipementAssocieModal.hide();
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
  }

  changeTitleModel(title: string) {
    this.actionDetailModalTitle = title;
  }

  checkUserProfil() {
    this.userProfil = localStorage.getItem('bixiApp_userProfil') || '';
    if (this.userProfil == 'M') {
      this.userProfilTitle = 'Mécanicien';
      this.activeProfilBg = 'bgColorMec';
    }
    if (this.userProfil == 'E') {
      this.userProfilTitle = 'Evaluateur';
      this.activeProfilBg = 'bgColorEval';
    }
    if (this.userProfil == 'S') {
      this.userProfilTitle = 'Superviseur';
      this.activeProfilBg = 'bg-success text-white';
    }
  }

  changeProfile(profil) {
    const profileFound = this.currentProfiles.find(
      (currentProfile) => currentProfile.profil == profil
    );
    localStorage.setItem('bixi-currentProfile', JSON.stringify(profileFound));
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }

  ngOnInit() {

    this.headerService.newMultipleCorrectifWOrkOrderEquipement.next(null)
    Notiflix.Notify.init({
      position: 'right-bottom',
    });

    // check if user has data in browser storage, else redirect himn to login page
    if (
      !this.storageService.getItem('bixi-user-auth') ||
      !this.storageService.getItem('bixi-currentCompany') ||
      !this.storageService.getItem('bixi-currentProfile')
    ) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );
      this.currentProfile = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      this.currentProfiles = JSON.parse(
        this.storageService.getItem('bixi-currentProfiles')
      );

      if (window.location.href.includes('?mode=topup-bt')) {
        this.equipementNo = this.storageService.getItem(
          'currentSerialNumber-window-popup-browser'
        );
      } else {
        this.equipementNo = this.storageService.getItem('currentSerialNumber');
      }

      if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
        let BlockAndReturnScan: StatusProfileBlockAndReturnScan  = JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'));
        this.isUserBlocked = BlockAndReturnScan ? BlockAndReturnScan?.isBlockedAction : true
      }
    
    }

    this.tryloop = 1

    this.getEquipement();

    this.isTemplateReadOnly =
      localStorage.getItem('bixiApp_isTemplateReadOnly') == '1' ? true : false;
    this.currentUserName = localStorage.getItem('bixiApp_currentUserName');
    this.checkUserProfil();
    this.editor = new Editor();
    this.insertDefaultTasks();
    // to display Popovers
    // var popoverTriggerList = [].slice.call(
    //   document.querySelectorAll('[data-bs-toggle="popover"]')
    // );
    // var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    //   return new bootstrap.Popover(popoverTriggerEl);
    // });

    this.editWorkOrderFeatures = this.accessService.getFeatureByProfile(
      this.currentProfile.profil
    );

    // this.headerService.maganePermssionEvent.subscribe(() => {
    //   this.managePermissions()
    // })
  }

  // templateStructureCreator(template: Template) {}

  getEquipement() {
  
    this.activatedRoute.params.subscribe((params) => {
      let tempWorkOrder = params['workOrderNo'];
      if (tempWorkOrder.includes(',')) {
        this.workOrdersNoArray = tempWorkOrder.split(',');

        this.workOrderNo = this.workOrdersNoArray[0];
      } else {
        this.workOrderNo = params['workOrderNo'];
        this.workOrdersNoArray.push(this.workOrderNo);
      }
      setTimeout(() => {
        this.generateCodeBar(this.workOrderNo)
        this.isPrintDetailBtVisible = 'd-none'
        this.isBarCodeVisible = 'none';
      }, 2000)
      this.openWorkOrderService
        .getWorkorders(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "no eq '" + this.workOrderNo + "'"
        )
        .subscribe({
          next: (resData) => {
            if (resData.value.length) {
              this.currentWorkorder = resData.value[0]
              if (resData.value[0].isBtRoue) {
                this.isBTRoue = true;
                this.BTRoue = resData.value[0];

                const assetNo = this.BTRoue.assetNo;
                this.homeService
                  .getEquipement(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    assetNo
                  )
                  .subscribe({
                    next: (roue) => {
                      this.roue = roue.value[0];
                      this.openWorkOrderService
                        .getTrackedItemsStatistics(
                          this.BIXI_userAuthObject.tenantId,
                          this.currentUserCompany.id,
                          "noActif eq '" + this.equipementNo + "'",
                          '$expand=trackedItemsbyActifs'
                        )
                        .subscribe({
                          next: (consumptionTrackData) => {
                            this.consumptionTrackData =
                              consumptionTrackData.value[0].trackedItemsbyActifs;
                          },
                          error: (error) => {
                            Notiflix.Loading.remove();
                            Notiflix.Notify.failure(error, {
                              timeout: 3000,
                              showOnlyTheLastOne: true, 
                              width: "400px",
                            });                          },
                        });
                    },
                    error: (error) => {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(error, {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      });
                    },
                  });
              }

                    // check if equipementNo is in url
      if (this.workOrderNo) {
        this.workOrderNo = this.workOrderNo.replace('%20', ' ');
   
        this.homeService
          .getEquipement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.equipementNo
          )
          .subscribe({
            next: (equipement) => {
              this.equipement = equipement.value[0];
              this.equipement.workOrderNo = this.currentWorkorder.no
              this.equipement.workOrderId = this.currentWorkorder.systemId
              this.equipement.workOrderBixiStatusDesc = this.currentWorkorder.workOrderBixiStatusDesc

              this.equipementNo = this.equipement.no;
              this.categoryCode = this.equipement.categoryCode;
              this.workOrderSystemId = this.equipement.systemId;
              this.storageService.setItem("Bixi_WorkOrderLedSysId", this.equipement?.workOrderId);
              this.storageService.setItem("Bixi_WorkOrderBixiStatus", this.equipement?.workOrderBixiStatus);  
           
              if(equipement.value[0].multiCorrectifWorkOrder){

                const workOrderLedSysId = this.storageService.setItem("Bixi_WorkOrderLedSysId", this.currentWorkorder.systemId);
                const currentWoStatus = this.storageService.setItem("Bixi_WorkOrderBixiStatus", this.currentWorkorder.eraBixiStatus);

                this.headerService.newMultipleCorrectifWOrkOrderEquipement.next( { equipement: equipement, BT: this.currentWorkorder} ) 

              }

              this.managePermissions();         
              this.openWorkOrderService
                .getTrackedItemsStatistics(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  "noActif eq '" + this.equipementNo + "'",
                  '$expand=trackedItemsbyActifs'
                )
                .subscribe({
                  next: (consumptionTrackData) => {
                    this.consumptionTrackData =
                      consumptionTrackData.value[0].trackedItemsbyActifs;
                  },
                  error: (error) => {},
                });
              this.templateMakerService
                .getPortailDefDisplayNames(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id
                )
                .subscribe({
                  next: (defDisplayNames) => {
                    this.defDisplayNames = defDisplayNames.value;
                  },
                  error: (error) => {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(error, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    });
                  },
                });

              let concatenatedUrl = '/locations';
              this.detailWorkorderService
                .getStandartApiData(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  concatenatedUrl
                )
                .subscribe({
                  next: (resData) => {
                    this.locationsList = resData.value.sort((a, b) =>
                      a.displayName.toLowerCase() > b.displayName.toLowerCase()
                        ? 1
                        : -1
                    );
                  },
                  error: (error) => {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(error, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, width: "400px",
                    });
                  },
                });
              concatenatedUrl = '/unitsOfMeasure';
              this.detailWorkorderService
                .getStandartApiData(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  concatenatedUrl
                )
                .subscribe({
                  next: (resData) => {
                    this.unitsOfMeasureList = resData.value.sort((a, b) =>
                      a.displayName.toLowerCase() > b.displayName.toLowerCase()
                        ? 1
                        : -1
                    );
                  },
                  error: (error) => {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(error, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    });
                  },
                });
              concatenatedUrl = '/items?$schemaversion='+this.schemaVersion+'&$filter=damMaintenanceItem eq true';
              this.detailWorkorderService
                .getNoStandartApiData(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  concatenatedUrl
                )
                .subscribe({
                  next: (resData) => {
                    this.itemsList = resData.value.sort((a, b) =>
                      a.no.toLowerCase() > b.no.toLowerCase() ? 1 : -1
                    );
                  },
                  error: (error) => {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(error, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    });
                  },
                });

              concatenatedUrl = '/itemVariants';
              this.detailWorkorderService
                .getNoStandartApiData_2(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  concatenatedUrl
                )
                .subscribe({
                  next: (resData) => {
                    this.allItemVariants = resData.value;
                  },
                  error: (error) => {},
                });

              concatenatedUrl = '/expenses?$schemaversion='+this.schemaVersion;
              this.detailWorkorderService
                .getNoStandartApiData(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  concatenatedUrl
                )
                .subscribe({
                  next: (resData) => {
                    this.expensesList = resData.value.sort((a, b) =>
                      a.no.toLowerCase() > b.no.toLowerCase() ? 1 : -1
                    );
                  },
                  error: (error) => {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(error, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    });
                  },
                });

              this.openWorkOrderService
                .getWorkorders(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id, 
                  "no eq '" + this.workOrderNo + "'"
                )
                .subscribe({
                  next: (workOrder) => {
                    this.currentWorkorder = workOrder.value[0];
                    if (workOrder.value.length > 0) {
                      this.createdByTemplateId = workOrder.value[0].createdByTemplateId;
                      this.detailWorkorderService
                        .getWorkOrderTemplate(
                          this.BIXI_userAuthObject.tenantId,
                          this.currentUserCompany.id,
                          this.createdByTemplateId,
                          "$filter=codeAssetCategory eq '" +
                            this.categoryCode +
                            "'"
                        )
                        .subscribe({
                          next: (workorderTemplate) => {
                            this.workorderTemplate = workorderTemplate;
                            if (this.workorderTemplate.status == 'Released') {
                              this.detailWorkorderService
                                .getTaskDetails(
                                  this.BIXI_userAuthObject.tenantId,
                                  this.currentUserCompany.id
                                )
                                .subscribe({
                                  next: (definitions) => {
                                    this.taskLineDefinitions =
                                      definitions.value;
                                    if (
                                      window.location.href.includes(
                                        '?wo-opened=false'
                                      )
                                    ) {
                                      this.closeOpenWorkorderpanelEvent.next();
                                    }
                                    this.getTaskCategoryByDocs();
                                  },
                                  error: (error) => {},
                                });
                            } else {
                              Notiflix.Notify.failure(
                                'Template est en cours de modification',
                                {
                                  timeout: 3000,
                                  showOnlyTheLastOne: true, width: "400px",
                                }
                              );
                              history.back();
                            }
                          },
                          error: (error) => {
                           
                            if(this.tryloop < 2) {
                              const roueAssetNomber = this.storageService.getItem(
                                'equipementRoueSerialNumber'
                              );
                              if(roueAssetNomber) {
                                this.storageService.setItem('currentSerialNumber', roueAssetNomber);
                                  this.storageService.setItem('equipementRoueSerialNumber', roueAssetNomber);
                                    this.ngOnInit()
                                }
                              this.tryloop++
                            }else  {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.failure(error, {
                                timeout: 3000,
                                showOnlyTheLastOne: true, 
                                width: "400px",
                              });
                            }
                          },
                        });

                      Notiflix.Loading.remove();
                    }
                  },

                  error: (error) => {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(error, {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    });
                  },
                });
              this.getBikeParts();
            },
            error: (error) => {},
          });
      } else {}
      
            }
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          },
        });

    });
  }

  refreshEquipementEvent() {
    this.getEquipement();
  }

  getTaskCategoryByDocs() {
    this.sameTasks = [];
    this.workOrdersNoArray.forEach((workOrderNo) => {
      this.detailWorkorderService
        .getTaskCategoryByDoc(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "$filter=documentNo eq '" + workOrderNo + "'"
        )
        .subscribe({
          next: (taskCategoryByDocs) => {
   
            this.taskCategoryByDocs = taskCategoryByDocs.value;
            this.workorderTemplate.columnLines.forEach((columnLine, i) => {
              columnLine.sectionLines.forEach((sectionLine, j) => {
                sectionLine.subSectionLines.forEach((subSectionLine, k) => {
                  subSectionLine.taskLines.forEach((taskLine, l) => {
                    const foundData = this.taskLineDefinitions.filter(
                      (taskLineDefinition) =>
                        taskLineDefinition.code == taskLine.displayName
                    );
                    if (foundData.length > 0) {
                      taskLine.definition = foundData[0];
                    } else {
                      taskLine.definition = undefined;
                    }
                    if (taskLine.taskCategoryByDoc == undefined) {
                  
                      const _foundData = this.taskCategoryByDocs.filter(
                        (taskCategoryByDoc) =>
                          taskCategoryByDoc.documentNo == workOrderNo &&
                          taskCategoryByDoc.codeTaskCategory ==
                            this.workorderTemplate.columnLines[i].sectionLines[
                              j
                            ].subSectionLines[k].taskLines[l].codeTaskCategory
                      );

                      if (_foundData.length > 0) {
                
                        taskLine.taskCategoryByDoc = _foundData[0];

                        if (this.sameTasks == null) {
                          this.sameTasks = {
                            [taskLine.systemId]: {
                              taskLine: taskLine,
                              workOrdersNo: [workOrderNo],
                            },
                          };
                        } else {
                          if (
                            typeof this.sameTasks[taskLine.systemId] ==
                            'undefined'
                          ) {
                            this.sameTasks[taskLine.systemId] = {
                              taskLine: taskLine,
                              workOrdersNo: [workOrderNo],
                            };
                          } else {
                            this.sameTasks[taskLine.systemId].workOrdersNo.push(
                              workOrderNo
                            );
                            this.sameTasks[taskLine.systemId].workOrdersNo = [
                              ...new Set(
                                this.sameTasks[taskLine.systemId].workOrdersNo
                              ),
                            ];
                          }
                        }

                  
                        if (this.globalSameTasks == null) {
                          this.globalSameTasks = {
                            [taskLine.systemId]: {
                              taskLine: taskLine,
                              workOrdersNo: [workOrderNo],
                            },
                          };
                         
                        } else {
                    
                          if (
                            typeof this.globalSameTasks[taskLine.systemId] ==
                            'undefined'
                          ) {
               
                            this.globalSameTasks[taskLine.systemId] = {
                              taskLine: taskLine,
                              workOrdersNo: [workOrderNo],
                            };
                          } else {
                            this.globalSameTasks[
                              taskLine.systemId
                            ].workOrdersNo.push(workOrderNo);
                            this.globalSameTasks[
                              taskLine.systemId
                            ].workOrdersNo = [
                              ...new Set(
                                this.globalSameTasks[
                                  taskLine.systemId
                                ].workOrdersNo
                              ),
                            ];
                          }
                        }
                      } else {
                        taskLine.taskCategoryByDoc = undefined;
                      }
                    } else {
               
                      if (this.workOrdersNoArray.length > 1) {
                        // do this check if only there is more than 1 workorder in the workOrdersNoArray

                        const _foundData = this.taskCategoryByDocs.filter(
                          (taskCategoryByDoc) =>
                            taskCategoryByDoc.codeTaskCategory ==
                            this.workorderTemplate.columnLines[i].sectionLines[
                              j
                            ].subSectionLines[k].taskLines[l].codeTaskCategory
                        );

                        if (_foundData.length > 0) {
                          if (this.sameTasks == null) {
                            this.sameTasks = {
                              [taskLine.systemId]: {
                                taskLine: taskLine,
                                workOrdersNo: [workOrderNo],
                              },
                            };
                          } else {
                            if (
                              typeof this.sameTasks[taskLine.systemId] ==
                              'undefined'
                            ) {
                              this.sameTasks[taskLine.systemId] = {
                                taskLine: taskLine,
                                workOrdersNo: [workOrderNo],
                              };
                            } else {
                              this.sameTasks[
                                taskLine.systemId
                              ].workOrdersNo.push(workOrderNo);
                              this.sameTasks[taskLine.systemId].workOrdersNo = [
                                ...new Set(
                                  this.sameTasks[taskLine.systemId].workOrdersNo
                                ),
                              ];
                            }
                          }
                          if (this.globalSameTasks == null) {
                            this.globalSameTasks = {
                              [taskLine.systemId]: {
                                taskLine: taskLine,
                                workOrdersNo: [workOrderNo],
                              },
                            };
                          } else {
                            if (
                              typeof this.globalSameTasks[taskLine.systemId] ==
                              'undefined'
                            ) {
                              this.globalSameTasks[taskLine.systemId] = {
                                taskLine: taskLine,
                                workOrdersNo: [workOrderNo],
                              };
                            } else {
                              this.globalSameTasks[
                                taskLine.systemId
                              ].workOrdersNo.push(workOrderNo);
                              this.globalSameTasks[
                                taskLine.systemId
                              ].workOrdersNo = [
                                ...new Set(
                                  this.globalSameTasks[
                                    taskLine.systemId
                                  ].workOrdersNo
                                ),
                              ];
                            }
                          }
                        }
                      }
                    }
                  });
                });
              });
            });
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          },
        });
    });
  }

  getRoundedValue(value: number): number {
    return Math.round(value);
  }

  popup_Bt_getTaskCategoryByDocs() {
    this.detailWorkorderService
      .getTaskCategoryByDoc(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "$filter=documentNo eq '" + this.roueWorkorder.no + "'"
      )
      .subscribe({
        next: (taskCategoryByDocs) => {
          this.popup_BtTaskCategoryByDocs = taskCategoryByDocs.value;
          this.templateRoue.columnLines.forEach((columnLine, i) => {
            columnLine.sectionLines.forEach((sectionLine, j) => {
              sectionLine.subSectionLines.forEach((subSectionLine, k) => {
                subSectionLine.taskLines.forEach((taskLine, l) => {
                  const foundData = this.taskLineDefinitions.filter(
                    (taskLineDefinition) =>
                      taskLineDefinition.code == taskLine.displayName
                  );
                  if (foundData.length > 0) {
                    taskLine.definition = foundData[0];
                  } else {
                    taskLine.definition = undefined;
                  }
                  if (taskLine.taskCategoryByDoc == undefined) {
                    const _foundData = this.popup_BtTaskCategoryByDocs.filter(
                      (taskCategoryByDoc) =>
                        taskCategoryByDoc.documentNo == this.roueWorkorder.no &&
                        taskCategoryByDoc.codeTaskCategory ==
                          this.templateRoue.columnLines[i].sectionLines[j]
                            .subSectionLines[k].taskLines[l].codeTaskCategory
                    );
                    if (_foundData.length > 0) {
                      taskLine.taskCategoryByDoc = _foundData[0];
                    }
                  }
                });
              });
            });
          });
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  endAllTasksClick() {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });

    const myArray = Object.values(this.globalSameTasks);

    myArray.forEach((data: any) => {
      const object = data
      const workOrdersNo = object.workOrdersNo;
      const workOrderNo = workOrdersNo[0];
      const taskLine = object.taskLine;
      this.openWorkOrderService
        .getWorkorders(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "no eq '" + workOrderNo + "'"
        )
        .subscribe({
          next: (openWorkOrdersData) => {
            const workorderObject = openWorkOrdersData.value[0];
            this.detailWorkorderService
              .getWorkOrderLines(
                this.BIXI_userAuthObject.tenantId,
                this.currentUserCompany.id,
                workorderObject.systemId,
                "$filter=type eq 'Item' and codeTaskCategory eq '" +
                  taskLine.codeTaskCategory +
                  "'"
              )
              .subscribe({
                next: (resData) => {
                  const itemWorkOrderLines = resData.value;
                  this.detailWorkorderService
                    .getWorkOrderLines(
                      this.BIXI_userAuthObject.tenantId,
                      this.currentUserCompany.id,
                      workorderObject.systemId,
                      "$filter=type eq 'Expense' and codeTaskCategory eq '" +
                        taskLine.codeTaskCategory +
                        "'"
                    )
                    .subscribe({
                      next: (resData) => {
                        const expenseWorkOrderLines = resData.value;
                        const dataArray = expenseWorkOrderLines.concat(itemWorkOrderLines);

                        if(dataArray.length > 0 ){
                          const scriptBcPermissions: Features = this.accessService.getFeatureByProfile(this.currentProfile.profil);
                          const isSkipControleOT = scriptBcPermissions ? scriptBcPermissions?.skipControleOT : false;
                          dataArray.forEach((selectedRecord, index) => {
                            let data = selectedRecord;
                            data.eraFinished = true;
                            data.skipControleOT = isSkipControleOT;
                            const concatenatedUrl = '/workOrderLines(' + selectedRecord.systemId + ')';
                            delete data.systemId;

                            this.detailWorkorderService
                              .editElement(
                                this.BIXI_userAuthObject.tenantId,
                                this.currentUserCompany.id,
                                workorderObject.systemId,
                                JSON.stringify(data),
                                concatenatedUrl
                              )
                              .subscribe({
                                next: (resData) => {
                                  if (index == dataArray.length - 1) {
  
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.success(
                                      'Tâches terminées avec succès',
                                      {
                                        timeout: 3000,
                                        showOnlyTheLastOne: true, 
                                        width: "400px",
                                      }
                                    );
                                    
                                    // setTimeout(()  => {
                                    //   Notiflix.Loading.remove();
                                    //   this.reloadCurrentRoute()
                                    // }, 3000)
                                
                                  }
                                },
                                error: (error) => {
                                  data.eraFinished = false;
                                  if (index == dataArray.length - 1) {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure(
                                      "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                                      {
                                        timeout: 3000,
                                        showOnlyTheLastOne: true, 
                                        width: "400px",
                                      }
                                    );
    
                                  }
                                },
                              });
                          });

                        }else{
                          
                          // this.reloadCurrentRoute()

                        }

  
                      },
                      error: (error) => {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.failure(
                          "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                          {
                            timeout: 3000,
                            showOnlyTheLastOne: true, 
                            width: "400px",
                          }
                        );
                      },
                    });
                },
                error: (error) => {
                  Notiflix.Notify.failure(
                    "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );
                },
              });
          },
          error: (error) => {
            Notiflix.Notify.failure(
              "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              }
            );
          },
        });
    });

    this.getEquipement();
  }

  validateAllTasksClick() {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    const myArray = Object.values(this.globalSameTasks);

    myArray.forEach((data: any) => {
      const object = data
      const workOrdersNo = object.workOrdersNo;
      const workOrderNo = workOrdersNo[0];
      const taskLine = object.taskLine;
      this.openWorkOrderService
        .getWorkorders(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "no eq '" + workOrderNo + "'"
        )
        .subscribe({
          next: (openWorkOrdersData) => {
            const workorderObject = openWorkOrdersData.value[0];
            this.detailWorkorderService
              .getWorkOrderLines(
                this.BIXI_userAuthObject.tenantId,
                this.currentUserCompany.id,
                workorderObject.systemId,
                "$filter=type eq 'Item' and codeTaskCategory eq '" +
                  taskLine.codeTaskCategory +
                  "'"
              )
              .subscribe({
                next: (resData) => {
                  const itemWorkOrderLines = resData.value;
                  this.detailWorkorderService
                    .getWorkOrderLines(
                      this.BIXI_userAuthObject.tenantId,
                      this.currentUserCompany.id,
                      workorderObject.systemId,
                      "$filter=type eq 'Expense' and codeTaskCategory eq '" +
                        taskLine.codeTaskCategory +
                       "'"
                    )
                    .subscribe({
                      next: (resData) => {
                        const expenseWorkOrderLines = resData.value;
                        const dataArray = expenseWorkOrderLines.concat(itemWorkOrderLines);

                        if(dataArray.length > 0 ){

                          dataArray.forEach((selectedRecord, index) => {
                            let data = selectedRecord;
                            data.eraApproved = true;
                            const saveSystemId = selectedRecord.systemId;
                            const concatenatedUrl =
                              '/workOrderLines(' + selectedRecord.systemId + ')';
                            delete data.systemId;
                            this.detailWorkorderService
                              .editElement(
                                this.BIXI_userAuthObject.tenantId,
                                this.currentUserCompany.id,
                                workorderObject.systemId,
                                JSON.stringify(data),
                                concatenatedUrl
                              )
                              .subscribe({
                                next: (resData) => {
                                  selectedRecord.systemId = saveSystemId;
                                  if (index == dataArray.length - 1) {
                                    Notiflix.Loading.remove();
  
                                    Notiflix.Notify.success(
                                      'Tâches validées avec succès',
                                      {
                                        timeout: 3000,
                                        showOnlyTheLastOne: true, width: "400px",
                                      }
                                    );

                                    // setTimeout(()  => {
                                    //   Notiflix.Loading.remove();
                                    //   this.reloadCurrentRoute()
                                    // }, 1000)

                                  }
                                },
                                error: (error) => {
                                  selectedRecord.systemId = saveSystemId;                               
                                  data.eraApproved = false;
                                  let errorMessage = '';
                                  if (
                                    error.includes('Finished doit')
                                  ) {
                                    errorMessage = 'Ces/cette tâche(s) doit/doivent être terminée pour être validée';
                                  } else {
                                    errorMessage = "Une erreur s'est produite pendant le traitement. Veuillez réessayer";
                                  }
                                  if (index == dataArray.length - 1) {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure(errorMessage, {
                                      timeout: 3000,
                                      showOnlyTheLastOne: true, 
                                      width: "400px",
                                    });
                                  }
                                },
                              });
                          });

                        }else{
                          
                            // this.reloadCurrentRoute()
  
                          }
                      },
                      error: (error) => {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.failure(
                          "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                          {
                            timeout: 3000,
                            showOnlyTheLastOne: true, 
                            width: "400px",
                          }
                        );
                      },
                    });
                },
                error: (error) => {
                  Notiflix.Notify.failure(
                    "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );
                },
              });
          },
          error: (error) => {},
        });
    });

    this.getEquipement();
  }


  onTaskDetailButtonClick(taskLine, isAutomaticOpend: boolean) {
    this.openDetailTacheModalEvent.next({
      isOpenedFromDetailWo: isAutomaticOpend,
      // eraMissingVariant: taskLine?.taskCategoryByDoc?.missingVariant,
      taskLine: taskLine,
      codeTaskCategory: taskLine.codeTaskCategory,
      workorderNo: this.workOrderNo,
      workorder: this.currentWorkorder,
      sameTasks: this.sameTasks,
    });
  }

  getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  endAllTasks() {
    if (this.isDefaultTesksSelected) {
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      setTimeout(() => {
        Notiflix.Notify.success('Tâches terminées avec succès!', {
          timeout: 3300,
          width: "400px",
        });
        Notiflix.Loading.remove();
      }, 1000);
    } else {
        Notiflix.Notify.warning(
          "Aune tâche sélectionnée!",
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );      
    }
  }

  validateAllTasks() {
    if (this.isDefaultTesksSelected) {
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      setTimeout(() => {
        Notiflix.Notify.success('Tâches validées avec succès!', {
          timeout: 3300, 
          width: "400px",
        });
        Notiflix.Loading.remove();
      }, 1000);
    } else {
      Notiflix.Notify.warning('Acune tâche sélectionnée!',
      {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
      });
    }
  }

  associateBtToArticles() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    setTimeout(() => {
      Notiflix.Notify.success('Bon de travail associé avec succès!', {
        timeout: 3300,
        width: "400px",
      });
      Notiflix.Loading.remove();
    }, 1000);
  }

  insertDefaultTasks() {
    if (!this.isDefaultTesksSelected) {
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      setTimeout(() => {
        this.defaultTesksBtnLabel = ' Enlever les tâches par défaut';
        this.isDefaultTesksSelected = true;
        this.activateDefaultTasksBtn(
          this.activeProfilBg,
          'text-white',
          'text-white',
          ''
        );
        Notiflix.Loading.remove();
      }, 1000);
    } else {
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      setTimeout(() => {
        this.defaultTesksBtnLabel = ' Insérer les tâches par défaut';
        this.isDefaultTesksSelected = false;
        this.activateDefaultTasksBtn('btn-light', 'text-info', '', 'd-none');
        Notiflix.Loading.remove();
      }, 1000);
    }
  }

  // addDefaultTaskBg() {
  //   Notiflix.Loading.standard('Chargement...', {
  //     svgColor: '#ffffff',
  //   });
  //   setTimeout(() => {
  //     this.activateDefaultTasksBtn('btn-light', 'text-info', '', 'd-none');
  //     Notiflix.Loading.remove();
  //   }, 1000);
  // }

  activateDefaultTasksBtn(
    bgTaskBtn,
    colorDefaultTaskIcon,
    colorTaskIcon,
    isTaskSelected
  ) {
    this.bgTaskBtn_Fr_Fr_A_D = bgTaskBtn;
    this.bgTaskBtn_Fr_Fr_A_G = bgTaskBtn;
    this.bgTaskBtn_Fr_Fr_C_D = bgTaskBtn;
    this.bgTaskBtn_Fr_Fr_C_G = bgTaskBtn;
    this.bgTaskBtn_Fr_Gr_G_D = bgTaskBtn;
    this.bgTaskBtn_Fr_Gr_G_G = bgTaskBtn;
    this.bgTaskBtn_Vi_Vi_A = bgTaskBtn;
    this.bgTaskBtn_Vi_Ca_C = bgTaskBtn;
    this.bgTaskBtn_Vi_Bo_C = bgTaskBtn;
    this.bgTaskBtn_Se_Wa_C = bgTaskBtn;
    this.bgTaskBtn_Gu_Ca_C = bgTaskBtn;
    this.bgTaskBtn_Gu_Co_C = bgTaskBtn;
    this.bgTaskBtn_Gs_Pl_A_G = bgTaskBtn;
    this.bgTaskBtn_Gs_Pl_C_G = bgTaskBtn;
    this.bgTaskBtn_Gs_Pl_A_D = bgTaskBtn;
    this.bgTaskBtn_Gs_Pl_C_D = bgTaskBtn;

    this.colorDefaultTaskIcon_Fr_Fr_A_D = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Fr_Fr_C_D = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Fr_Fr_A_G = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Fr_Fr_C_G = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Fr_Gr_G_D = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Fr_Gr_G_G = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Vi_Vi_A = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Vi_Ca_C = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Vi_Bo_C = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Se_Wa_C = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Gu_Ca_C = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Gu_Co_C = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Gs_Pl_A_G = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Gs_Pl_C_G = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Gs_Pl_A_D = colorDefaultTaskIcon;
    this.colorDefaultTaskIcon_Gs_Pl_C_D = colorDefaultTaskIcon;

    this.colorTaskIcon_Fr_Fr_A_D = colorTaskIcon;
    this.colorTaskIcon_Fr_Fr_A_G = colorTaskIcon;
    this.colorTaskIcon_Fr_Fr_C_D = colorTaskIcon;
    this.colorTaskIcon_Fr_Fr_C_G = colorTaskIcon;
    this.colorTaskIcon_Fr_Gr_G_D = colorTaskIcon;
    this.colorTaskIcon_Fr_Gr_G_G = colorTaskIcon;
    this.colorTaskIcon_Vi_Vi_A = colorTaskIcon;
    this.colorTaskIcon_Vi_Ca_C = colorTaskIcon;
    this.colorTaskIcon_Vi_Bo_C = colorTaskIcon;
    this.colorTaskIcon_Se_Wa_C = colorTaskIcon;
    this.colorTaskIcon_Gu_Ca_C = colorTaskIcon;
    this.colorTaskIcon_Gu_Co_C = colorTaskIcon;
    this.colorTaskIcon_Gs_Pl_A_G = colorTaskIcon;
    this.colorTaskIcon_Gs_Pl_C_G = colorTaskIcon;
    this.colorTaskIcon_Gs_Pl_A_D = colorTaskIcon;
    this.colorTaskIcon_Gs_Pl_C_D = colorTaskIcon;

    this.isTaskSelected_Fr_Fr_A_D = isTaskSelected;
    this.isTaskSelected_Fr_Fr_A_G = isTaskSelected;
    this.isTaskSelected_Fr_Fr_C_D = isTaskSelected;
    this.isTaskSelected_Fr_Fr_C_G = isTaskSelected;
    this.isTaskSelected_Fr_Gr_G_D = isTaskSelected;
    this.isTaskSelected_Fr_Gr_G_G = isTaskSelected;
  }

  isTaskBtnDisabled(e): boolean{
    if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
      const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
      const scriptBcPermissions: Features = this.accessService.getFeatureByProfile(this.currentProfile.profil);

      this.disableProgressBarFinish = (statusApiPermissions.isBlockedAction || !scriptBcPermissions?.terminate) ? true: false;
      if (e.data == 'taskCategoryByDoc'){
        //remove task
        this.disabledTaskBtn = (statusApiPermissions.isBlockedAction || !scriptBcPermissions?.removeTask) ? true: false;
      }
      else if(e.data == 'taskLine'){
        //add task
        this.disabledTaskBtn = (statusApiPermissions.isBlockedAction || !scriptBcPermissions?.addTask) ? true: false;
      }
      else{
        this.disabledTaskBtn = true;
      }
    }
    return this.disabledTaskBtn
  }

  updateTaskOnWorkOrder(e) {
    if (!this.disabledTaskBtn){
      this.EObjectToUpdate = e;
      if (e.data == 'taskCategoryByDoc') {
        if (
          e.object.taskCategoryByDoc.createdByProfil == 'Supervisor' ||
          e.object.taskCategoryByDoc.createdByProfil == 'Evaluator'
        ) {
          if (
            this.currentProfile.profil == 'Supervisor' ||
            this.currentProfile.profil == 'Evaluator'
          ) {
            this.canRemoveTask(e);
          } 
          else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              "Vous n'avez pas le droit de retirer cette tâche car vous êtes un " +
                this.communService.roleEnglishToFrench[
                  this.currentProfile.profil
                ],
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              }
            );
          }
        } else if (e.object.taskCategoryByDoc.createdByProfil == 'Mechanic') {
          this.canRemoveTask(e);
        }
      } 
      else if (e.data == 'taskLine') {
        if (e.object.fieldType == 'Selection') {
          this.AddTaskToWorkOrder(e);
        } else if (
          e.object.fieldType == 'X (Expenses)' ||
          e.object.fieldType == 'X (Items)' ||
          e.object.fieldType == 'X (Items/Expenses)'
        ) {
          if(this.associatedEquipTemplateModal){
            this.associatedEquipTemplateModal.hide();
          }
          var myModal = new bootstrap.Modal(
            document.getElementById('mulitipeValueTaskLineModal'),
            {}
          );
          myModal.toggle();
        } else {
          Notiflix.Notify.failure(
            'FieldType inconnue. Veuillez reconfigurer le template',
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
        }
      }
    }
    else this.displayPermissionErrorMessage();
  } 

  popup_BtUpdateTaskOnWorkOrder(e) {
    if (!this.disabledTaskBtn){
      this.EObjectToUpdate = e;
      if (e.data == 'taskCategoryByDoc') {
        if (
          e.object.taskCategoryByDoc.createdByProfil == 'Supervisor' ||
          e.object.taskCategoryByDoc.createdByProfil == 'Evaluator'
        ) {
          if (
            this.currentProfile.profil == 'Supervisor' ||
            this.currentProfile.profil == 'Evaluator'
          ) {
            this.popup_BtCanRemoveTask(e);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(
              "Vous n'avez pas le droit de retirer cette tâche car vous êtes un " +
                this.communService.roleEnglishToFrench[
                  this.currentProfile.profil
                ],
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              }
            );
          }
        } else if (e.object.taskCategoryByDoc.createdByProfil == 'Mechanic') {
          this.popup_BtCanRemoveTask(e);
        }
      } 
      else if (e.data == 'taskLine') {
        if (e.object.fieldType == 'Selection') {
          this.popup_BtAddTaskToWorkOrder(e);
        } else if (
          e.object.fieldType == 'X (Expenses)' ||
          e.object.fieldType == 'X (Items)' ||
          e.object.fieldType == 'X (Items/Expenses)'
        ) {
          this.associatedEquipTemplateModal.hide();

          var myModal = new bootstrap.Modal(
            document.getElementById('popup_BtMulitipeValueTaskLineModal'),
            {}
          );
          myModal.toggle();
        } else {
          Notiflix.Notify.failure(
            'FieldType inconnue. Veuillez reconfigurer le template',
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
        }
      }
    }
    else this.displayPermissionErrorMessage();
  }

  canRemoveTask(e) {
    const systemId = e.object.taskCategoryByDoc.systemId;
    const concatenatedUrl = '/taskCategoriesByDocs(' + systemId + ')';
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.detailWorkorderService
      .deleteElement(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        concatenatedUrl
      )
      .subscribe({
        next: (resData) => {
          this.getTaskCategoryByDocs();
          Notiflix.Loading.remove();
          e.object.taskCategoryByDoc = undefined;
          Notiflix.Notify.success('Tâche retirée avec succès', {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  onMultipleTaskCancel() {
    if (
      this.associatedEquipTemplateModal &&
      !this.associatedEquipTemplateModal._isShown
    ) {
      this.associatedEquipTemplateModal.show();
    }
  }

  popup_BtCanRemoveTask(e) {
    const systemId = e.object.taskCategoryByDoc.systemId;
    const concatenatedUrl = '/taskCategoriesByDocs(' + systemId + ')';
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.detailWorkorderService
      .deleteElement(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        concatenatedUrl
      )
      .subscribe({
        next: (resData) => {
          this.popup_Bt_getTaskCategoryByDocs();
          Notiflix.Loading.remove();
          e.object.taskCategoryByDoc = undefined;
          Notiflix.Notify.success('Tâche retirée avec succès', {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  addTaskByMultipleValue() {
    if (this.EObjectToUpdate !== null) {
      if (
        this.taskMultipleValue < this.EObjectToUpdate.object.fieldMINValue ||
        this.taskMultipleValue > this.EObjectToUpdate.object.fieldMAXValue
      ) {
        var myModal = new bootstrap.Modal(
          document.getElementById('mulitipeValueTaskLineModal'),
          {}
        );
        myModal.toggle();
        Notiflix.Notify.failure(
          'Le multiplicateur doit être compris entre la valeur Min et la valeur Max.',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      } else {
        this.AddTaskToWorkOrder(this.EObjectToUpdate);
      }
    }
  }

  popup_BtAddTaskByMultipleValue() {
    if (this.EObjectToUpdate !== null) {
      if (
        this.taskMultipleValue < this.EObjectToUpdate.object.fieldMINValue ||
        this.taskMultipleValue > this.EObjectToUpdate.object.fieldMAXValue
      ) {
        var myModal = new bootstrap.Modal(
          document.getElementById('popup_BtMulitipeValueTaskLineModal'),
          {}
        );
        myModal.toggle();
        Notiflix.Notify.failure(
          'Le multiplicateur doit être compris entre la valeur Min et la valeur Max.',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      } else {
        this.popup_BtAddTaskToWorkOrder(this.EObjectToUpdate);
      }
    }
  }

  AddTaskToWorkOrder(e) {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.detailWorkorderService
      .getWorkOrderCategoriesWithFilter(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "code eq '" + e.object.codeTaskCategory + "'"
      ).subscribe({
        next: (resData) => {
          const concatenatedUrl = '/taskCategoriesByDocs';
          const data = {
            codeTaskCategory: e.object.codeTaskCategory,
            codeTaskCategoryId: resData.value[0].systemId,
            createdByProfil: this.currentProfile.profil,
            createdByTemplate: this.workorderTemplate.code,
            createdByTemplateId: this.createdByTemplateId,
            documentId: this.currentWorkorder.systemId,
            documentNo: this.workOrderNo,
            multiple: e.object.fieldType == 'Selection' ? 1 : this.taskMultipleValue,
            documentType: 'Order',
          };
          this.detailWorkorderService
            .createElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              data,
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                e.object.taskCategoryByDoc = resData;
                e.object.codeTaskCategory = resData.codeTaskCategory;
                console.log("***", this.sameTasks[e.object.systemId])
                if (typeof this.sameTasks[e.object.systemId] == 'undefined') {
                  this.sameTasks[e.object.systemId] = {
                    taskLine: e.object,
                    workOrdersNo: [this.currentWorkorder.no],
                  };
                } 
                else {
                  if (
                    !this.sameTasks[e.object.systemId].workOrdersNo.includes(
                      this.currentWorkorder.no
                    )
                  ) {
                    this.sameTasks[e.object.systemId].workOrdersNo.push(
                      this.currentWorkorder.no
                    );
                    this.sameTasks[e.object.systemId].workOrdersNo = [...new Set(
                        this.sameTasks[e.object.systemId].workOrdersNo
                      ),
                    ];
                  }
                }    

                if (this.globalSameTasks == null) {
                  this.globalSameTasks = {
                    [e.object.systemId]: {
                      taskLine: e.object,
                      workOrdersNo: [this.currentWorkorder.no],
                    },
                  };
                } 
                else {
                  if (
                    typeof this.globalSameTasks[e.object.systemId] ==
                    'undefined'
                  ) {
                    this.globalSameTasks[e.object.systemId] = {
                      taskLine: e.object,
                      workOrdersNo: [this.currentWorkorder.no],
                    };
                  } else {
                    this.globalSameTasks[
                      e.object.systemId
                    ].workOrdersNo.push(this.currentWorkorder.no);
                    this.globalSameTasks[
                      e.object.systemId
                    ].workOrdersNo = [
                      ...new Set(
                        this.globalSameTasks[
                          e.object.systemId
                        ].workOrdersNo
                      ),
                    ];
                  }
                }
                Notiflix.Notify.success('Tâche ajoutée avec succès', {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
                if(this.editWorkOrderFeatures.displayAutomaticDetaiTaskPopup){
                    this.getTaskCategoryBySystemId(e.object, resData.systemId)
                }
                else{
                  Notiflix.Loading.remove();
                }
                   
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

    getTaskCategoryBySystemId(taskline, taskSysId) {

    this.detailWorkorderService
      .getTaskCategoryBySystemId(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        taskSysId
      )
      .subscribe({
        next: (taskCategoryByDoc) => {
          Notiflix.Loading.remove();
          if(taskCategoryByDoc){
            if(taskCategoryByDoc?.missingVariant)
              this.onTaskDetailButtonClick(taskline, true)
          }
        },
        error: (error) => {
          Notiflix.Loading.remove();
        },
      });
  }

  popup_BtAddTaskToWorkOrder(e) {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });

    this.detailWorkorderService
      .getWorkOrderCategoriesWithFilter(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "code eq '" + e.object.codeTaskCategory + "'"
      )
      .subscribe({
        next: (resData) => {
          const concatenatedUrl = '/taskCategoriesByDocs';
          const data = {
            codeTaskCategory: e.object.codeTaskCategory,
            codeTaskCategoryId: resData.value[0].systemId,
            createdByProfil: this.currentProfile.profil,
            createdByTemplate: this.templateRoue.code,
            createdByTemplateId: this.createdByTemplateId,
            documentId: this.roueWorkorder.systemId,
            documentNo: this.roueWorkorder.no,
            multiple:
              e.object.fieldType == 'Selection' ? 1 : this.taskMultipleValue,
            documentType: 'Order',
          };

          this.detailWorkorderService
            .createElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              data,
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                Notiflix.Loading.remove();

                e.object.taskCategoryByDoc = resData;
                e.object.codeTaskCategory = resData.codeTaskCategory;

                if (
                  this.associatedEquipTemplateModal &&
                  !this.associatedEquipTemplateModal._isShown
                ) {
                  this.associatedEquipTemplateModal.show();
                }

                Notiflix.Notify.success('Tâche ajoutée avec succès', {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  clickFrFrAjArBtn() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    setTimeout(() => {
      if (this.bgTaskBtn_Fr_Fr_A_D == 'btn-light') {
        this.bgTaskBtn_Fr_Fr_A_D = this.activeProfilBg;
        this.colorDefaultTaskIcon_Fr_Fr_A_D = 'text-white';
        this.colorTaskIcon_Fr_Fr_A_D = '';
        this.isTaskSelected_Fr_Fr_A_D = '';
      } else {
        this.bgTaskBtn_Fr_Fr_A_D = 'btn-light';
        this.colorDefaultTaskIcon_Fr_Fr_A_D = 'text-info';
        this.colorTaskIcon_Fr_Fr_A_D = '';
        this.isTaskSelected_Fr_Fr_A_D = 'd-none';
      }
      Notiflix.Loading.remove();
    }, 1000);
  }

  clickFrFrChAvBtn() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    setTimeout(() => {
      if (this.bgTaskBtn_Fr_Fr_C_G == 'btn-light') {
        this.bgTaskBtn_Fr_Fr_C_G = this.activeProfilBg;
        this.colorDefaultTaskIcon_Fr_Fr_C_G = 'text-white';
        this.colorTaskIcon_Fr_Fr_C_G = '';
        this.isTaskSelected_Fr_Fr_C_G = '';
      } else {
        this.bgTaskBtn_Fr_Fr_C_G = 'btn-light';
        this.colorDefaultTaskIcon_Fr_Fr_C_G = 'text-info';
        this.colorTaskIcon_Fr_Fr_C_G = '';
        this.isTaskSelected_Fr_Fr_C_G = 'd-none';
      }
      Notiflix.Loading.remove();
    }, 1000);
  }

  clickFrFrChArBtn() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    setTimeout(() => {
      if (this.bgTaskBtn_Fr_Fr_C_D == 'btn-light') {
        this.bgTaskBtn_Fr_Fr_C_D = this.activeProfilBg;
        this.colorDefaultTaskIcon_Fr_Fr_C_D = 'text-white';
        this.colorTaskIcon_Fr_Fr_C_D = '';
        this.isTaskSelected_Fr_Fr_C_D = '';
      } else {
        this.bgTaskBtn_Fr_Fr_C_D = 'btn-light';
        this.colorDefaultTaskIcon_Fr_Fr_C_D = 'text-info';
        this.colorTaskIcon_Fr_Fr_C_D = '';
        this.isTaskSelected_Fr_Fr_C_D = 'd-none';
      }
      Notiflix.Loading.remove();
    }, 1000);
  }

  clickFrGrGrAvBtn() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    setTimeout(() => {
      if (this.bgTaskBtn_Fr_Gr_G_G == 'btn-light') {
        this.bgTaskBtn_Fr_Gr_G_G = this.activeProfilBg;
        this.colorDefaultTaskIcon_Fr_Gr_G_G = 'text-white';
        this.colorTaskIcon_Fr_Gr_G_G = '';
        this.isTaskSelected_Fr_Gr_G_G = '';
      } else {
        this.bgTaskBtn_Fr_Gr_G_G = 'btn-light';
        this.colorDefaultTaskIcon_Fr_Gr_G_G = 'text-info';
        this.colorTaskIcon_Fr_Gr_G_G = '';
        this.isTaskSelected_Fr_Gr_G_G = 'd-none';
      }
      Notiflix.Loading.remove();
    }, 1000);
  }

  clickFrGrGrArBtn() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    setTimeout(() => {
      if (this.bgTaskBtn_Fr_Gr_G_D == 'btn-light') {
        this.bgTaskBtn_Fr_Gr_G_D = this.activeProfilBg;
        this.colorDefaultTaskIcon_Fr_Gr_G_D = 'text-white';
        this.colorTaskIcon_Fr_Gr_G_D = '';
        this.isTaskSelected_Fr_Gr_G_D = '';
      } else {
        this.bgTaskBtn_Fr_Gr_G_D = 'btn-light';
        this.colorDefaultTaskIcon_Fr_Gr_G_D = 'text-info';
        this.colorTaskIcon_Fr_Gr_G_D = '';
        this.isTaskSelected_Fr_Gr_G_D = 'd-none';
      }
      Notiflix.Loading.remove();
    }, 1000);
  }

  getTaskLineIcon(
    displayName,
    columnLineIndex,
    sectionLineIndex,
    subSectionLineIndex,
    taskLineIndex
  ) {
    const dataFound = this.defDisplayNames.filter(
      (defDisplayName) => defDisplayName.code == displayName
    );
    if (dataFound.length > 0) {
      const content64 = dataFound[0].content64;
      const element: any = document.getElementById(
        'taskLine_icon_' +
          columnLineIndex +
          '_' +
          sectionLineIndex +
          '_' +
          subSectionLineIndex +
          '_' +
          taskLineIndex
      );

      if (element !== null) {
        if (content64.trim() !== '') {
          element.src = 'data:image/png;base64,' + content64;
        } else {
          element.style.display = 'none';
        }
      }
    }
  }

  onCancelNewLine() {
    if (this.actionDetailModal && !this.actionDetailModal._isShown) {
      this.actionDetailModal.show();
    }
  }

  generateCodeBar(value: String) {
    this.isBarCodeVisible = 'block';
    this.barcode.value = value;
  }

  addFooters(doc, barcodeImage){
    const pageCount = doc.internal.getNumberOfPages()
    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      // Add the page number
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, { align: 'center' })
      // Add the barcode at the bottom
      const barcodeWidth = 50; // Adjust as needed
      const barcodeHeight = 20; // Adjust as needed
      const xPosition = 10; // Center horizontally
      const yPosition = doc.internal.pageSize.height - barcodeHeight - 10; // 10 mm from the bottom
      doc.addImage(barcodeImage, 'PNG', xPosition, yPosition, barcodeWidth, barcodeHeight);
    }
  }

  checkDivHeight(){
    const sectiontoprintElement: any = document.getElementById('sectiontoprint')
    this.pdPageHeight += 50
    
    if(sectiontoprintElement.clientHeight > 0 && this.pdPageHeight >= 30000){
        this.drawForPdf_index++
    }
  }

  //Function to Adjuft report before generate PDF
  adjustReportBeforeGeneratePdf(){
    let tableDetailHeight = 80;
    let footerHeight = 165;
    let pageBodyHeight = 1250;
    let columnsHeights = [];
    let columns = this.colum.nativeElement.querySelectorAll('.cardColum');
    let parts = this.detail.nativeElement.querySelectorAll('.cardBikeParts');
    let pagesNumber = [];
    if(columns.length > 0){
      for(let i=0; i<columns.length; i++){
        let heightCounter = 0;
        let heightColum = tableDetailHeight+20;
        let pageNumber = 1;
        let sections = columns[i].querySelectorAll('.cardSection');
        for(let j=0; j<sections.length; j++){
          let diffHeight = pageBodyHeight-heightCounter;     
          if(diffHeight< sections[j].offsetHeight){
            sections[j].style.marginTop = (tableDetailHeight+footerHeight+diffHeight+50)?.toString()+"px";
            heightCounter = sections[j].offsetHeight;
            heightColum += sections[j].offsetHeight+(tableDetailHeight+footerHeight+diffHeight+50);
            pageNumber++;
          }
          else{
            sections[j].classList.add('mt-2');  
            heightCounter = heightCounter + sections[j].offsetHeight;     
            heightColum += sections[j].offsetHeight+20;    
          }
        }
          columnsHeights.push(heightColum);
          pagesNumber.push(pageNumber);
      }
      if(parts.length > 0){
        const maxPages = Math.max(...pagesNumber);
        const maxColumHeight = Math.max(...columnsHeights);
        let pageDimension = (tableDetailHeight+footerHeight)+pageBodyHeight+50;
        let partsMarginTop =((pageDimension * maxPages) - maxColumHeight);
        parts[0].style.marginTop = Math.abs(partsMarginTop) + tableDetailHeight + (maxPages >1 ? footerHeight : 80)+"px";
      }      
    }
  }

// Function to generate the PDF
  generateDetailBtPdf(isPdfToSend: boolean) {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.isPrintDetailBtVisible = '';
    setTimeout(() => {
    this.adjustReportBeforeGeneratePdf();
      // Capture the main content
      var data = document.getElementById('sectiontoprint');
      html2canvas(data).then((canvas) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jspdf.jsPDF('p', 'mm');
        doc.addImage(
          canvas,
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          '',
          'FAST'
        );
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(
            canvas,
            'PNG',
            0,
            position,
            imgWidth,
            imgHeight,
            '',
            'FAST'
          );
          heightLeft -= pageHeight;
        }
        // Capture the barcode element
        const barcodeElement = document.getElementById('pdf_barcode');  

        html2canvas(barcodeElement).then((barcodeCanvas) => {
          const barcodeImage = barcodeCanvas.toDataURL('image/png');
          // Add footers and barcode to each page
          this.addFooters(doc, barcodeImage);
          // Uncomment the line below to enable downloading the PDF
          // doc.save(`Detai l BT N° ${this.equipement.workOrderNo}.pdf`);
          if(!isPdfToSend){
            Notiflix.Loading.remove();
            this.isPrintDetailBtVisible = 'd-none';
            window.open(doc.output('bloburl'));
            this.refreshGetEquipement();
          }
          else{
            const blobReport = doc.output('blob');
            const fileReport = new File([blobReport], "");
            this.isPrintDetailBtVisible = 'd-none';
            if (fileReport){
              this.uploadDetailBtPdfIntoSharepoint(fileReport)
            } 
            else{
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(`Erreur survenue lors de génération du rapport pour le BT ${this.workOrderNo}`,
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
              this.refreshGetEquipement();
            }           
          } 
        });           
      });
    });
  }

  private getUserName():string{
    let userName = "UnknownUser";
    const user = this.storageService.getItem('bixi-user-auth');
    if(user){
      userName = JSON.parse(user)?.account?.name
    }
    return userName;
  }

// Function to send genereted PDF to Sharpoint via Power automate
  uploadDetailBtPdfIntoSharepoint(fileReport: File) {
    if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
      let BlockAndReturnScan: StatusProfileBlockAndReturnScan  = JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'));
      let isScanReturn = BlockAndReturnScan ? BlockAndReturnScan?.isRetrunScan : false    
      const powerAutomateUri = AppConfigService.settings.powerAutomateSharepointUri;
      const createDate = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss', 'en');
      const userName = this.getUserName();
      const fromStatus =  this.equipement.workOrderBixiStatus || 'UnknownStatus';
      const reportName = `${this.equipementNo}_${this.workOrderNo}_${fromStatus}_${createDate}_${userName}_vv`    
      const reader = new FileReader();
      reader.readAsDataURL(fileReport);
      reader.onload = () => {
        const data = {
          filename: reportName,
          $content: reader.result?.toString()?.replace(/^data:.*?;base64,/, ""),
        };
        this.http.post(powerAutomateUri, data).subscribe({
          next: (x) => {
            Notiflix.Notify.success(`Le rapport du détail BT ${this.workOrderNo} a été envoyé avec succès vers Sharepoint`,
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
            if(isScanReturn){
              this.router.navigateByUrl(`home/${this.currentUserCompany.id}/${this.currentProfile.id}`);  
              Notiflix.Loading.remove();      
            }
            else if(BlockAndReturnScan?.currentStatus == 'REP-VALID'){
              history.back();
            }
            else{
              this.refreshGetEquipement();
            }        
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(`Erreur lors d'envoi du rapport de détail BT ${this.workOrderNo} vers Sharepoint`,
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });          
            if(isScanReturn){
              this.router.navigateByUrl(`home/${this.currentUserCompany.id}/${this.currentProfile.id}`);        
            }
            else{
              this.refreshGetEquipement();
            }                
          },
        });
      };
    }
  }


  getBikeParts() {
    this.detailWorkorderService
      .getBikeParts(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "leadWONo eq '" + this.equipement?.workOrderNo + "'"
      )
      .subscribe({
        next: (result) => {
          if (result?.value && result?.value?.length > 0) {
            if (result.value) {
              this.bikePartsData = result.value[0].eraworkOrderLines;
            }
          }
        },
        error: (error) => {},
      });
  }

  reopenAllFinishedTasks() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    const myArray = Object.values(this.globalSameTasks);

    myArray.forEach((data: any) => {
      const object = data
      const workOrdersNo = object.workOrdersNo;
      const workOrderNo = workOrdersNo[0];
      const taskLine = object.taskLine;
      this.openWorkOrderService
        .getWorkorders(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "no eq '" + workOrderNo + "'"
        )
        .subscribe({
          next: (openWorkOrdersData) => {
            const workorderObject = openWorkOrdersData.value[0];
            this.detailWorkorderService
              .getWorkOrderLines(
                this.BIXI_userAuthObject.tenantId,
                this.currentUserCompany.id,
                workorderObject.systemId,
                "$filter=type eq 'Item' and codeTaskCategory eq '" +
                  taskLine.codeTaskCategory +
                  "'"
              )
              .subscribe({
                next: (resData) => {
                  const itemWorkOrderLines = resData.value;
                  this.detailWorkorderService
                    .getWorkOrderLines(
                      this.BIXI_userAuthObject.tenantId,
                      this.currentUserCompany.id,
                      workorderObject.systemId,
                      "$filter=type eq 'Expense' and codeTaskCategory eq '" +
                        taskLine.codeTaskCategory +
                        "'"
                    )
                    .subscribe({
                      next: (resData) => {
                        const expenseWorkOrderLines = resData.value;
                        const dataArray =
                          expenseWorkOrderLines.concat(itemWorkOrderLines);

                          if(dataArray.length > 0 ){

                            dataArray.forEach((selectedRecord, index) => {
                              let data = selectedRecord;
                              if (data.eraFinished == true) {
                              } else {
                              }
                              data.eraFinished = false;
                              const concatenatedUrl =
                                '/workOrderLines(' + selectedRecord.systemId + ')';
                              delete data.systemId;
                              this.detailWorkorderService
                                .editElement(
                                  this.BIXI_userAuthObject.tenantId,
                                  this.currentUserCompany.id,
                                  workorderObject.systemId,
                                  JSON.stringify(data),
                                  concatenatedUrl
                                )
                                .subscribe({
                                  next: (resData) => {
                                    if (index == dataArray.length - 1) {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.success(
                                        'Tâches terminées sont réouvries avec succès',
                                        {
                                          timeout: 3000,
                                          showOnlyTheLastOne: true, 
                                          width: "400px",
                                        }
                                      );
                                    }
                                  },
                                  error: (error) => {
                                    data.eraFinished = false;
                                    if (index == dataArray.length - 1) {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.failure(error, {
                                        timeout: 3000,
                                        showOnlyTheLastOne: true, 
                                        width: "400px",
                                      });

                                      // setTimeout(()  => {
                                      //   Notiflix.Loading.remove();
                                      //   this.reloadCurrentRoute()
                                      // }, 1000)

                                    }
                                  },
                                });
                            });

                          }else{
                          
                            // this.reloadCurrentRoute()
  
                          }
                      },
                      error: (error) => {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.failure(error, {
                          timeout: 3000,
                          showOnlyTheLastOne: true, 
                          width: "400px",
                        });
                      },
                    });
                },
                error: (error) => {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                },
              });
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          },
        });
    });
    this.getEquipement();
  }

  reopenAllValidatedTasks() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    const myArray = Object.values(this.globalSameTasks);

    myArray.forEach((data: any) => {
      const object = data
      const workOrdersNo = object.workOrdersNo;
      const workOrderNo = workOrdersNo[0];
      const taskLine = object.taskLine;
      this.openWorkOrderService
        .getWorkorders(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "no eq '" + workOrderNo + "'"
        )
        .subscribe({
          next: (openWorkOrdersData) => {
            const workorderObject = openWorkOrdersData.value[0];
            this.detailWorkorderService
              .getWorkOrderLines(
                this.BIXI_userAuthObject.tenantId,
                this.currentUserCompany.id,
                workorderObject.systemId,
                "$filter=type eq 'Item' and codeTaskCategory eq '" +
                  taskLine.codeTaskCategory +
                  "'"
              )
              .subscribe({
                next: (resData) => {
                  const itemWorkOrderLines = resData.value;
                  this.populateItemsArrayEvent.next(itemWorkOrderLines)
                  this.detailWorkorderService
                    .getWorkOrderLines(
                      this.BIXI_userAuthObject.tenantId,
                      this.currentUserCompany.id,
                      workorderObject.systemId,
                      "$filter=type eq 'Expense' and codeTaskCategory eq '" +
                        taskLine.codeTaskCategory +
                        "'"
                    )
                    .subscribe({
                      next: (resData) => {
                        const expenseWorkOrderLines = resData.value;

                        this.populateExpensesArrayEvent.next(expenseWorkOrderLines)

                        const dataArray =
                          expenseWorkOrderLines.concat(itemWorkOrderLines);

                          if(dataArray.length > 0 ){

                            dataArray.forEach((selectedRecord, index) => {
                              let data = selectedRecord;
                              data.eraApproved = false;
                              const saveSystemId = selectedRecord.systemId;
                              const concatenatedUrl =
                                '/workOrderLines(' + selectedRecord.systemId + ')';
                              delete data.systemId;
                              this.detailWorkorderService
                                .editElement(
                                  this.BIXI_userAuthObject.tenantId,
                                  this.currentUserCompany.id,
                                  workorderObject.systemId,
                                  JSON.stringify(data),
                                  concatenatedUrl
                                )
                                .subscribe({
                                  next: (resData) => {
                                    selectedRecord.systemId = saveSystemId;
                                    if (index == dataArray.length - 1) {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.success(
                                        'Tâches validées sont réouvries avec succès',
                                        {
                                          timeout: 3000,
                                          showOnlyTheLastOne: true, 
                                          width: "400px",
                                        }
                                      );
                                    }
                                  },
                                  error: (error) => {
                                    selectedRecord.systemId = saveSystemId;
                                    data.eraApproved = false;
                                    if (index == dataArray.length - 1) {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.failure(error, {
                                        timeout: 3000,
                                        showOnlyTheLastOne: true, 
                                        width: "400px",
                                      });       
                                      
                                      // setTimeout(()  => {
                                      //   Notiflix.Loading.remove();
                                      //   this.reloadCurrentRoute()
                                      // }, 1000)
                                      
                                    }
                                  },
                                });
                            });

                          }else{
                          
                            // this.reloadCurrentRoute()
  
                          }
                      },
                      error: (error) => {
                        Notiflix.Loading.remove();
                        Notiflix.Notify.failure(error, {
                          timeout: 3000,
                          showOnlyTheLastOne: true, 
                          width: "400px",
                        });
                      },
                    });
                },
                error: (error) => {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure(error, {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  });
                },
              });
          },
          error: (error) => {},
        });
    });
    this.getEquipement();
  }

  refreshGetEquipement() {
    this.reloadCurrentRoute()
  }

  onBtClose() {
    if (
      this.equipementAssocieModal &&
      !this.equipementAssocieModal._isShown
    ) {
      this.equipementAssocieModal.show();
    }
  }

  processOnProgressBarClick(taskLine: any, type: string) {
    this.progressBarLineTask = null;
    if (taskLine && taskLine?.taskCategoryByDoc) {
      this.progressBarLineTask = taskLine;
      if(this.sameTasks?.[taskLine.systemId]?.workOrdersNo?.length == 1 || this.isBTRoue) {
        if (type == 'f') {
          if (taskLine.taskCategoryByDoc?.finishedRate == 100) {
            if (!this.disableProgressBarFinish){
              this.reopenAllFinishedTasksOnProgressBarClick(taskLine);
            }else { this.displayPermissionErrorMessage();}
          } else {
            if (!this.disableProgressBarFinish){
              this.finishAllTasksOnProgressBarClick(taskLine);
            }else { this.displayPermissionErrorMessage(); }
          }
        } else if (type == 'v') {
          if (taskLine.taskCategoryByDoc?.approuvedRate == 100) {
            if (!this.disableProgressBarApprouve){
              this.reopenAllValidatedTasksOnProgressBarClick(taskLine);
            }else this.displayPermissionErrorMessage();
          } else {
            if (!this.disableProgressBarApprouve){
              this.validateAllTasksOnProgressBarClick(taskLine);
            }else this.displayPermissionErrorMessage();
          }
        } else {
        }
      }
      else {
      Notiflix.Notify.warning(
        "Cette tâche se retrouve en double sur plusieurs BT différents.",{
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        }
      );
    }
    }else{
      Notiflix.Notify.failure(
        "Veuillez ajouter cette tâche au bon de travail",
        {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        }
      );
    }
  } 

  finishAllTasksOnProgressBarClick(taskLine) {
    console.log(this.currentWorkorder.no);
      const tmpBT = this.isBTRoue ? this.currentWorkorder.no : this.sameTasks[taskLine.systemId].workOrdersNo[0];
      this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ tmpBT +"'").subscribe({
        next: (workOrder) => {
          this.getWorkOrderLines(
            workOrder.value[0],
            this.progressBarLineTask,
            'ff'
          );      
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true,
            width: "400px",
          })
        }
      })
  }

  reopenAllFinishedTasksOnProgressBarClick(taskLine) {
      const tmpBT = this.isBTRoue ? this.currentWorkorder.no : this.sameTasks[taskLine.systemId].workOrdersNo[0];
      this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ tmpBT +"'").subscribe({
        next: (workOrder) => {
          this.getWorkOrderLines(
            workOrder.value[0],
            this.progressBarLineTask,
            'fr'
          );
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true,
            width: "400px",
          })
        }
      })
  }

  validateAllTasksOnProgressBarClick(taskLine) {
      const tmpBT = this.isBTRoue ? this.currentWorkorder.no : this.sameTasks[taskLine.systemId].workOrdersNo[0];
      this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ tmpBT +"'").subscribe({
        next: (workOrder) => {
          this.getWorkOrderLines(
            workOrder.value[0],
            this.progressBarLineTask,
            'vv'
          );      
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true,
            width: "400px",
          })
        }
      })
  }

  reopenAllValidatedTasksOnProgressBarClick(taskLine) {
      const tmpBT = this.isBTRoue ? this.currentWorkorder.no : this.sameTasks[taskLine.systemId].workOrdersNo[0];
      this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ tmpBT +"'").subscribe({
        next: (workOrder) => {      
          this.getWorkOrderLines(
            workOrder.value[0],
            this.progressBarLineTask,
            'vr'
          );      
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true,
            width: "400px",
          })
        }
      })
  }

  private displayPermissionErrorMessage() {
    const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
    if(statusApiPermissions && statusApiPermissions.isBlockedAction){
      Notiflix.Notify.warning(
          "Le statut en cours "+ statusApiPermissions.currentStatus+" du bon de travail bloque cette opération pour le profil " +
            this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
    }
    else {
      Notiflix.Notify.warning(
          "Vous ne pouvez pas effectuer cette action car vous êtes un " +
            this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
    }
  }

  getWorkOrderLines(workorder: any, taskLine, mode) {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.BTandLines = { BT: workorder, expenses: [], items: [] };
    this.detailWorkorderService
      .getWorkOrderLines(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        workorder.systemId,
        "$filter=type eq 'Item' and codeTaskCategory eq '" +
          taskLine.codeTaskCategory +
          "'"
      )
      .subscribe({
        next: (resData) => {
          this.itemWorkOrderLines = resData.value;
          this.BTandLines.items = this.itemWorkOrderLines;
          this.populateItemsArrayEvent.next(this.itemWorkOrderLines)
          this.detailWorkorderService
            .getWorkOrderLines(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              workorder.systemId,
              "$filter=type eq 'Expense' and codeTaskCategory eq '" +
                taskLine.codeTaskCategory +
                "'"
            )
            .subscribe({
              next: (resData) => {
                Notiflix.Loading.remove();
                this.expenseWorkOrderLines = resData.value;
                this.populateExpensesArrayEvent.next(this.expenseWorkOrderLines)
                this.BTandLines.expenses = this.expenseWorkOrderLines;
                if (mode) {   
                  switch (mode) {
                    case 'ff':
                      this.onSelectedAllFinishedClickEvent.next({
                        info_1: null,
                        info_2: true,
                        workOrder: this.BTandLines.BT
                      });
                      this.getEquipement();
                      break;
                    case 'fr':
                      this.onReopenGeneralClickEvent.next({
                        info_1: 'finished',
                        info_2: false,
                        workOrder: this.BTandLines.BT
                      });
                      this.getEquipement();
                      break;
                    case 'vv':
                      this.onSelectedAllValidateClickEvent.next({
                        info_1: 'finished',
                        info_2: false,
                        workOrder: this.BTandLines.BT
                      });
                      this.getEquipement();
                      break;
                    case 'vr':
                      this.onReopenGeneralClickEvent.next({
                        info_1: 'validated',
                        info_2: false,
                        workOrder: this.BTandLines.BT
                      });
                      this.getEquipement();
                      break;
                    default:
                      break;
                  }
                }
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

}
